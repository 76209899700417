import { BaseElement, html, css } from 'Components';

class Part extends BaseElement {
  static get styles() {
    return [
      css`
        .tlp {
          text-align: center;
          color: #111111;
          padding:0px;
          padding-left:10px;
          padding-right:10px;
          width: initial !important;
          display: inline-block;
          border-radius: 5px;
          -webkit-print-color-adjust: exact;
          color-adjust: exact;
        }

        .tlp.red {
          background-color:#FF2B2B;
          color:white;
        }

        .tlp.amber {
          background-color:#FFC000;
        }

        .tlp.green {
          background-color:#33FF00;
        }

        .tlp.clear {
          background-color:#FFFFFF;
        }
      `
    ];
  }

  static get properties() {
    return {
      name: { type: String }
    };
  }

  constructor() {
    super();
    this.name = 'red';
  }

  render() {
    return html`<span class="tlp ${this.name}">TLP:${this.name.toUpperCase()}</span>`;
  }

}

customElements.define('tlp-level', Part);