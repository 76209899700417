import { BaseElement, html, css, unsafeHTML } from 'Components';
import { Session } from 'Utils';

import styles from './styles.js';

class Block extends BaseElement {
  static get styles() {
    return [
      styles,
      css`
        m-icon[name="hourglass_empty"] {
          font-size:80px;
          margin-bottom:50px;
        }

        .center {
          text-align:center;
        }
      `
    ];
  }

  render() {
    return html`
      <div class="item">
        <p class="center">
          <br/><br/>
          <m-icon name="hourglass_empty" nogradient></m-icon>
          <br/>
          Merci de patientez, le chargement des données<br/>peut prendre jusqu'à 2 minutes.<br/>
        </p>
        <br/>
        <sl-progress-bar indeterminate></sl-progress-bar>
      </div>
    `;
  }

}

customElements.define('incident-loading', Block);