import { BaseElement, html, css } from 'Components';

class Part extends BaseElement {
  static get styles() {
    return [
      css`
        h3.title {
          display:flex;
          justify-content:space-between;
          font-size:1.2em;
          align-items:center;
          padding:10px;
          margin:1px;
          border:0px;
          -webkit-print-color-adjust: exact;
          color-adjust: exact;
          text-align:center;
        }

        h3.title div {
          font-size:1.4em;
        }

        h3.title span {
          height:33px;
        }

        img[name="sysdream"] {
          visibility:hidden;
        }

        .logo {
          width:200px;
          height:60px;
          background-size:contain !important;
          background-repeat:no-repeat;
          margin-right:20px;
          text-align:-right;
        }

        .logo img {
          display:none;
        }

        .cert-aviation-france {
          margin-top:-5px;
        }

        .cert-aviation-france.white {
          background:url(assets/logos/cert-aviation-blanc.webp) no-repeat;
        }

        .cert-aviation-france.blue {
          background:url(assets/logos/cert-aviation-bleu.webp) no-repeat;
        }

        .plb {
          background:url(assets/logos/plb.png) no-repeat;
        }

        tlp-level {
          display:none;
        }

        @media print {

          tlp-level {
            display:block;
          }

          .blue .cert-aviation-france {
            background:url(assets/logos/cert-aviation-blanc.webp) no-repeat;
          }

          .sysdream {
            background:url(assets/logos/sysdream_color.svg) no-repeat;
            width:150px;
            height:40px;
            background-size:contain !important;
            background-repeat:no-repeat;
            background-position:center;
            margin-right:20px;
          }

          img[name="sysdream"] {
            visibility:visible;
          }

          .blue {
            background-color:var(--nsys-blue5);
          }
        }

      `
    ];
  }

  static get properties() {
    return {
      parent: { type: Object },
      company: { type: String },
    };
  }

  constructor() {
    super();
    this.parent = null;
    this.company = null;

    this.compagnies = {
      'cert-aviation-france': {
        dark: html`<div class="logo cert-aviation-france white"></div>`,
        light: html`<div class="logo cert-aviation-france blue"></div>`,
        toggle:true,
      },
      'csirt aviation france': {
        dark: html`<div class="logo cert-aviation-france white"></div>`,
        light: html`<div class="logo cert-aviation-france blue"></div>`,
        toggle:true,
      },
      'plb': {
        dark: html`<img src="assets/logos/plb.png" width="350">`,
        light: html`<img src="assets/logos/plb.png" width="350">`,
        toggle:false,
      },
      'oxand': {
        dark: html`<img src="assets/logos/oxand.png" width="200">`,
        light: html`<img src="assets/logos/oxand.png" width="200">`,
        toggle:false,
      },
      'haudecoeur': {
        dark: html`<img src="assets/logos/haudecoeur.jpg" width="250">`,
        light: html`<img src="assets/logos/haudecoeur.jpg" width="250">`,
        toggle:false,
      },
      'ozitem': {
        dark: html`<img src="assets/logos/ozitem.webp" width="250">`,
        light: html`<img src="assets/logos/ozitem.webp" width="250">`,
        toggle:true,
      },
      'tutelaire': {
        dark: html`<img src="assets/logos/tutelaire.svg" width="250">`,
        light: html`<img src="assets/logos/tutelaire.svg" width="250">`,
        toggle:false,
      },
      'motul': {
        dark: html`<img src="assets/logos/motul.svg" width="200">`,
        light: html`<img src="assets/logos/motul.svg" width="200">`,
        toggle:true,
      },
      'lalouviere': {
        dark: html`<img src="assets/logos/lalouviere.png" width="400">`,
        light: html`<img src="assets/logos/lalouviere.png" width="400">`,
        toggle:false,
      },
      'sysdream':{
        dark: html`<div class="sysdream"></div>`,
        light: html`<div class="sysdream"></div>`,
        toggle:true,
      }
    };
  }

  getLogo() {
    let logo;
    if (document.documentElement.className.match(/dark/)) {
      logo = this.compagnies[this.company].dark;
    } else {
      logo = this.compagnies[this.company].light;
    }

    logo = html`<div>${logo}</div>`;
    return logo
  }

  getToggleColor() {
    return this.compagnies[this.company].toggle;
  }

  firstUpdated() {
    super.firstUpdated();
    this.company = this.parent.ticket?.affiliate[0] || this.parent.who;
    this.company = this.company.toLowerCase();
  }

  render() {
    if (!this.company) return;

    return html`
      <h3 class="title ${this.getToggleColor() ? 'blue': ''}">
        ${this.getLogo()}
      </h3>
    `;
  }

}

customElements.define('incident-header', Part);