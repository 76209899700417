import { BaseElement, html, css } from 'Components';

class Part extends BaseElement {
  static get styles() {
    return [
      css`
        @media print {
          :host {
            display:none;
          }
        }
      `
    ];
  }

  static get properties() {
    return {
      parent: { type: Object }
    };
  }

  constructor() {
    super();
    this.parent = null;
  }


  render() {
    if (!this.parent.ticket) return;
    if (!this.parent.isResolved()) return;

    return html`
      <box-styled variant="warning" micon="warning" layout="row">Cette alerte a été résolue par ${this.parent.getAnalysteInfo('realName')} le ${this.parent.formatDate(this.parent.ticket.resolved)}.</box-styled>
    `;
  }

}

customElements.define('warning-closed', Part);